import "../styles/Layout.scss";
import { NavLink, Outlet } from "react-router-dom";
import Navbar2 from "./Navbar2";
import Footer from "./Footer";
import Footer2 from "./Footer2";
export default function Layout() {

  const logoUrl = process.env.PUBLIC_URL + "/logo192.png";
  const menuItems = [
    // { title: 'Sectors', url: '/Sectors', iconName: 'fa-solid fa-gear' },
    // { title: 'Platform', url: '/Platform', iconName: 'fa-solid fa-robot' },
    { title: 'Software', url: '', iconName: 'fa-solid fa-robot' },
    { title: 'Algorithms', url: '/Algorithms', iconName: 'fa-solid fa-laptop-code' },
    { title: 'R&D', url: '/Research', iconName: 'fa-solid fa-book-atlas' },
    { title: 'Team', url: '/Team', iconName: 'fa-solid fa-people-group' },
    { title: 'Enquire', url: '/Enquire', iconName: 'fa-solid fa-envelope' },
  ];

  return (
    <div className="Layout">
      <Navbar2 logoUrl={logoUrl} menuItems={menuItems} />
      <main className={"flex-1"}>
        <Outlet />
      </main>
      <Footer2 />
    </div>
  );
}
