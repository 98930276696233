import React, { useState , useEffect } from "react";
import "../styles/Navbar2Styles.scss";

const Navbar2 = ({ logoUrl, menuItems }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isPlatformMenuOpen, setIsPlatformMenuOpen] = useState(true);
  const [selectedLanguage, setSelectedLanguage] = useState("EN");

  useEffect(() => {
    // Load language from localStorage
    const savedLanguage = localStorage.getItem("selectedLanguage");
    if (savedLanguage) {
      setSelectedLanguage(savedLanguage);
    }
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const togglePlatformMenu = () => {
    setIsPlatformMenuOpen(!isPlatformMenuOpen);
  };

  

  const handleLanguageChange = (e) => {
    const newLanguage = e.target.value;
    setSelectedLanguage(newLanguage);
    localStorage.setItem("selectedLanguage", newLanguage);
  };

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <a href="/">
          <img src={logoUrl} alt="Logo" />
        </a>
      </div>

      <div className={`navbar-menu ${menuOpen ? "open" : ""}`}>
        <a href="#" className="Software" onClick={togglePlatformMenu}>
          <i className="fa-solid fa-cubes" />
          Software
          <i
            className={
              isPlatformMenuOpen
                ? "fa-solid fa-angle-down"
                : "fa-solid fa-angle-right"
            }
            style={{ paddingLeft: 10 }}
          />
          <div className="dropdown-content">
            <ul className="sub-menu">
              <li>
                <a href="/Platform">
                  <i className="fa-solid fa-robot" />
                  Platform
                </a>
              </li>
              <li>
                <a href="/Sectors">
                  <i className="fa-solid fa-gear" />
                  Sectors
                </a>
              </li>
              <li>
                <a href="/Download">
                  <i className="fa-solid fa-download" />
                  Download
                </a>
              </li>
            </ul>
          </div>
          <ul
            className={
              isPlatformMenuOpen
                ? "Responsive_SubMenu"
                : "Active_Responsive_SubMenu"
            }
          >
            <li>
              <a href="/Platform">
                <i className="fa-solid fa-robot" />
                Platform
              </a>
            </li>
            <li>
              <a href="/Sectors">
                <i className="fa-solid fa-gear" />
                Sectors
              </a>
            </li>
            <li>
              <a href="/Download">
                <i className="fa-solid fa-download" />
                Download
              </a>
            </li>
          </ul>
        </a>

        <a href="/Algorithms" className="Algorithms">
          <i className="fa-solid fa-laptop-code" />
          Algorithms
        </a>

        <a href="/Research" className="RnD">
          <i className="RnDIcon" />
          Research & Development
        </a>

        <a href="/Team" className="Team">
          <i className="fa-solid fa-people-group" />
          Team
        </a>

        <a href="/Achievements" className="News">
          <i className="fa-solid fa-newspaper" />
          Achievements
        </a>

        <a href="/Enquire" className="Enquire">
          <i className="fa-solid fa-envelope" />
          Enquire
        </a>

        {/* Language Dropdown
        <div className="language-dropdown">
          <select
            value={selectedLanguage}
            onChange={handleLanguageChange}
            className="language-select"
          >
            <option value="EN">EN</option>
            <option value="NL">NL</option>
            {/* Add more languages as needed }
          </select>
        </div> */}
      </div>

      <div className="navbar-toggle" onClick={toggleMenu}>
        <div className={`bar ${menuOpen ? "open" : ""}`} />
        <div className={`bar ${menuOpen ? "open" : ""}`} />
        <div className={`bar ${menuOpen ? "open" : ""}`} />
        <div className={`bar ${menuOpen ? "open" : ""}`} />
      </div>
    </nav>
  );
};

export default Navbar2;
