import "../styles/Sectors.scoped.scss";
import { SectorsData } from "../components/SectorsData";
import Herodom from "../components/Herodom";
import NavbarTD from "../components/NavbarTD";

// import { Parallax, ParallaxLayer, IParallax } from "@react-spring/parallax";

function Sectors() {
  return (
    <>
      <div className="Scontainer">
        <NavbarTD />
        <div className="Sectorsnapp">
          {SectorsData.map((item,index) => (
            <div className="sectionS" id={item.section}>
              <Herodom
                title={item.title}
                img={item.banner}
                info={item.info}
                btn=""
                index={index}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
export default Sectors;
