import React from "react";


import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/LogoRollingBar.scss";

import { images } from "./PartnerImages";
 const doubleLogo = [...images, ...images]
const LogoRollingBar = () => {
  return (
    <div className="slider">
      <div className="slide-track">
        {doubleLogo.map((logo, index) => (
          <div className="slide">
            <img
              className="pLogo"
              src={process.env.PUBLIC_URL + logo}
              height="100"
              width="250"
              alt=""
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default LogoRollingBar;
