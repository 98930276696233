import { react } from "react";
import { useEffect, useState } from "react";

import "../styles/Hero.scss";
import {
  MouseParallaxChild,
  MouseParallaxContainer,
} from "react-parallax-mouse";
import { motion, useAnimation } from "framer-motion";

import { useInView } from "react-intersection-observer";
import { Row, Col, Container, Button } from "react-bootstrap";

const Herodom = (props) => {
  const { ref, inView } = useInView({
    threshold: 0,
  });
  const animation = useAnimation();
  const animation_1 = useAnimation();

  useEffect(() => {
    if (inView) {
      animation.start({
        x: 0,
        opacity: 1,
        transition: {
          type: "spring",
          duration: 2,
          bounce: 0.3,
          delay: 0.8,
        },
      });
      animation_1.start({
        x: 0,
        opacity: 1,
        transition: {
          type: "spring",
          duration: 2,
          bounce: 0.3,
          delay: 0.5,
        },
      });
    }
    if (!inView) {
      animation.start({
        x: -100,
        opacity: 0,
      });

      animation_1.start({
        x: -100,
        opacity: 0,
      });
    }

    // console.log("use effect hook, inView =", inView);
  }, [inView]);
  let element = <></>;
  if (props.index > 1) {
    element = (

        <Col xs={12} className="comingSoon">
          <h1 className="comingSoonTitle">
          <span className="uns">COMING SOON</span>
          </h1>
        </Col>

    );
  } else {
    element = <div className="columns">{props.info}</div>;
  }
  return (
    <>
      <Container>
        <Row>
          <Col lg={1}></Col>
          <Col lg={10} ref={ref} className="a">
            <Row>
              <Col></Col>
              <Col>
                <div>
                  <MouseParallaxContainer
                    className="parallax"
                    containerStyle={{
                      width: "100%",
                      display: "grid",
                      gridTemplateColumns: "auto auto auto auto auto",
                    }}
                    globalFactorX={0.5}
                    globalFactorY={0.5}
                    resetOnLeave
                  >
                    <MouseParallaxChild
                      factorX={0.5}
                      factorY={0.5}
                    ></MouseParallaxChild>
                    <MouseParallaxChild factorX={0.5} factorY={0.5}>
                      <div>
                        <motion.img
                          className="sectorImg"
                          animate={animation_1}
                          src={props.img}
                        />
                      </div>
                    </MouseParallaxChild>
                  </MouseParallaxContainer>
                </div>
              </Col>
              <Col></Col>
            </Row>
            <Row>
              <Col>
                <motion.div animate={animation}>
                  <Row>
                    <h1 className="sectorTitle">{props.title}</h1>
                  </Row>
                  <Row>{element}</Row>
                </motion.div>
              </Col>
            </Row>
          </Col>
          <Col lg={1}></Col>
        </Row>
      </Container>
    </>
  );
};

export default Herodom;
