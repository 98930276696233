import React from 'react';
import { Col, Container, Row } from "react-bootstrap";
import Timeline from '../components/Timeline';

function News() {
  return (
    <Container>
      <Row style={{ marginTop: "20vh" }}>
        <Col>
          <Timeline />
        </Col>
      </Row>
    </Container>
  );
}

export default News;
