import React, { useEffect, useState } from 'react';
import '../styles/AnimatedSvg.scss';

const AnimatedSVG = ({ svgCode }) => {
  const [loaded, setLoaded] = useState(false);
  const [circles, setCircles] = useState([]);
  const startingCx = calculateStartingCx();

  useEffect(() => {
    const parser = new DOMParser();
    const svgDocument = parser.parseFromString(svgCode, 'image/svg+xml');
    const svgCircles = svgDocument.getElementsByTagName('circle');

    const parsedCircles = Array.from(svgCircles).map((circle) => ({
      finalCx: parseInt(circle.getAttribute('cx')),
      cy: parseInt(circle.getAttribute('cy')),
      r: parseInt(circle.getAttribute('r')),
    }));



    setCircles(parsedCircles);

    // Delay the transition to allow time for rendering
    const timeout = setTimeout(() => {
      setLoaded(true);
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, [svgCode]);

  function calculateStartingCx() {
    const pageWidth = window.innerWidth;
    const svgWidth = 800; // Update this value to match your SVG's width
    const startingCx = (pageWidth - svgWidth) / 2;
    return startingCx;
  }

  return (
    <svg className={`animated-svg ${loaded ? 'loaded' : ''}`} viewBox="0 0 800 600">
      {circles.map((circle, index) => (
        <circle
          key={index}
          cx={loaded ? circle.finalCx : startingCx}
          cy={circle.cy}
          r={circle.r}
        />
      ))}
    </svg>
  );
};

export default AnimatedSVG;