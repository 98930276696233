import ParticlesComponent from "../components/Particles";
import { Row, Col, Container } from "react-bootstrap";
import MainMoto from "../components/Moto";
import LogoRollingBar from "../components/Roll";


function Home() {
  return (
    <Container style={{ height: "100%" }}>
      <Row style={{ marginTop: "25vh" }}>
        {/* <div className="page first"> */}
        <MainMoto />
        {/* </div> */}
      </Row>
      <Row style={{ height: "400px", marginBottom: "150px" }}>
        <ParticlesComponent className="particles-wrapper" />
      </Row>


      <Row style={{ marginTop: ".5vh", marginBottom: "15px" }}>
        <Col className="d-flex justify-content-center">
          <h4 className="PartnersHeader">Our Partners</h4>
        </Col>
      </Row>

      <Row>
        <LogoRollingBar delay={2} />
      </Row>
    </Container>
  );
}
export default Home;
