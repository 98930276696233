import React, { useEffect, useRef } from "react";
// import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
// import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
// import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
// import NativeSelect from "@mui/material/NativeSelect";
// import InputBase from "@mui/material/InputBase";
// import FormHelperText from "@mui/material/FormHelperText";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import lottie from 'lottie-web';
import animationData from '../assets/mail.json';

 import emailjs from "@emailjs/browser";

const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#3f51b5",
    },
    secondary: {
      main: "#2196f3",
    },
  },
});

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function isEmail(val) {
  let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if(!regEmail.test(val)){
    return 0;
  }
  return 1;
}

export default function Enquire() {
  const formRef = useRef(null);
  const [sector, setSector] = React.useState("");
  // const [open, setOpen] = React.useState(false);
  const animationContainer = useRef(null);
  let animationInstance = useRef(null);

  useEffect(() => {
    animationInstance.current = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: animationData,
    });

    return () => {
      if (animationInstance.current) {
        animationInstance.current.destroy();
        animationInstance.current = null;
      }
    };
  }, []);

  const [state, setState] = React.useState({
    open: false,
    type: 'success',
    message: 'Message Sent.',
  });
  const { open, type, message } = state;

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   const data = new FormData(event.currentTarget);
  //   console.log({
  //     email: data.get("email"),
  //     password: data.get("password"),
  //   });
  // };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setState(false,'success','Message Sent');
  };

  const sendTest = (e) => {
    e.preventDefault();
    const formData = new FormData(formRef.current);
    // console.log(formData.get("companyName"));
    // console.log(formData.get("email"));
    // console.log(formData.get("sector"));

    if(isEmail(formData.get("email"))===0){
      console.log(" Invalid Email");
      setState({
        open: true,
        type: "error",
        message: "Please enter a valid Email!",
      });
      return;
    }
    
    emailjs.send("service_ixqr23s","template_j8xmmf8",{
      email: formData.get("email"),
      companyName: formData.get("companyName"),
      sector: formData.get("sector"),
      description: formData.get("description"),
      },"7pyXVbFMe_xYtDucY").then(
              (result) => {
                setState({open: true,type:'success',message:'Message Sent'});
                console.log(result.text);
              },
              (error) => {
                setState({open: true,type:'error',message: "There was an Error sending message!"});
                console.log(error.text);
              }
            );


  }
  // const sendEmail = (e) => {
  //   e.preventDefault();

  //   emailjs
  //     .sendForm(
  //       "service_e0p26lt",
  //       "YOUR_TEMPLATE_ID",
  //       form.current,
  //       "7pyXVbFMe_xYtDucY"
  //     )
  //     .then(
  //       (result) => {
  //         console.log(result.text);
  //       },
  //       (error) => {
  //         console.log(error.text);
  //       }
  //     );
  // };

  const handleChange = (event: SelectChangeEvent) => {
    const updatedSector = event.target.value;
    setSector(updatedSector);
    // console.log(updatedSector);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container
        component="main"
        maxWidth="xs"
        sx={{ marginTop: 15, marginBottom: 10 }}
      >
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box sx={{ m: 1 }}>
            {/*<div ref={animationContainer} style={{ width: '150px', height: '150px' }}/>*/}
          </Box>
            <Box
              component="form"
              ref={formRef}
              noValidate
              onSubmit={sendTest}
              sx={{ mt: 3 }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="given-name"
                    name="companyName"
                    required
                    fullWidth
                    id="companyName"
                    label="Company Name"
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl sx={{ minWidth: 190 }}>
                    <InputLabel id="demo-simple-select-helper-label">
                      Your Sector
                    </InputLabel>
                    <Select
                      name="sector"
                      labelId="sector"
                      id="sector"
                      value={sector}
                      label="Your Sector"
                      onChange={handleChange}
                    >
                      <MenuItem value="None">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value={"A.I.Development"}>A.I. Development</MenuItem>
                      <MenuItem value={"Intelligence Services"}>Intelligence Services</MenuItem>
                      <MenuItem value={"Procurement"}>Procurement</MenuItem>
                      <MenuItem value={"Logistics"}>Logistics</MenuItem>

                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    name="description"
                    label="Message"
                    type="description"
                    id="description"
                    multiline
                    maxRows={8}
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Enquire
              </Button>
              <Snackbar  open={open} autoHideDuration={6000} onClose={handleClose}>
              <Alert onClose={handleClose} severity={type} sx={{ width: '100%' }}>
                {message}
              </Alert>
          </Snackbar>
            </Box>

        </Box>
      </Container>
    </ThemeProvider>
  );
}
