import React, { useEffect, useRef } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import SNN from "../assets/SNNFC.png";
import "../styles/Research.scss";
import Complete from "../assets/Complete.gif";
import InProgress from "../assets/Inprogress.gif";
import INSFTP from "../assets/IntSFP.png"

import animationData from '../assets/Complete.json';
import lottie from 'lottie-web';

const Item = ({ delay, children }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  React.useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      transition={{ duration: 0.5, delay }}
      variants={{
        visible: { opacity: 1, x: 0 },
        hidden: { opacity: 0, x: -100 }
      }}
    >
      {children}
    </motion.div>
  );
};

const Research = () => {

  const animationContainer1 = useRef(null);
  const animationContainer2 = useRef(null);

  useEffect(() => {
    const delay = 1000; // Delay in milliseconds (1000ms = 1 second)

    // Start the first animation with a delay
    const timeout1 = setTimeout(() => {
      const animationInstance1 = lottie.loadAnimation({
        container: animationContainer1.current,
        renderer: 'svg',
        loop: false,
        autoplay: true,
        animationData: animationData,
      });

      // Cleanup for the first animation
      return () => animationInstance1.destroy();
    }, delay);

    // Start the second animation with a delay
    const timeout2 = setTimeout(() => {
      const animationInstance2 = lottie.loadAnimation({
        container: animationContainer2.current,
        renderer: 'svg',
        loop: false,
        autoplay: true,
        animationData: animationData,
      });

      // Cleanup for the second animation
      return () => animationInstance2.destroy();
    }, delay);

    // Clear timeouts if the component unmounts before the delay
    return () => {
      clearTimeout(timeout1);
      clearTimeout(timeout2);
    };
  }, []);


  return (
    <div className="research-container">

      <div className="research-content">
      <div className="research-title-container">
      <motion.h1
        className="research-maintitle"
        initial={{ rotateX: 0, opacity: 0 }}
        animate={{ rotateX: 0, opacity: 1 }}
        transition={{ duration: 2, delay: 0.2 }}
      >
        Research Projects
      </motion.h1>
      </div>
        <div className="research-block">
          <Item delay={0.2}>
            <h2 className="research-SubMoto research-identifier">MIT FEASIBILITY STUDY 2022</h2>
          </Item>
          <Item delay={0.4}>
            <div className="research-title" style={{ display: 'flex', alignItems: 'center' }}>
           Grant ID MITH22107 <div className="research-anims" ref={animationContainer1} style={{ width: '64px', height: '64px' }}/>
             </div>
            <div className="research-title">Grant Applicant: <img alt="International Services For Professionals" src={INSFTP} className="research-logo" /></div>
          </Item>
          <Item delay={0.6}>
            <p className="research-paragraph">This research study was conducted into exploring the feasibility of setting up a new integrated development environment, which could be either local or cloud-based in nature, to facilitate co-development & pair-programming, in contrast to using IDE solutions on the market such as <a href="https://code.visualstudio.com/" target="_blank" rel="noreferrer"> Visual Studio Code </a> or <a href="https://github.com/features/codespaces" target="_blank" rel="noreferrer">Github CodeSpaces</a>. </p>
          </Item>
          </div>
          <div className="research-block">
            <Item delay={0.8}>
              <h2 className="research-SubMoto research-identifier">MIT FEASIBILITY STUDY 2023</h2>
            </Item>
            <Item delay={1}>
              <div className="research-title" style={{ display: 'flex', alignItems: 'center' }}>
                Grant ID: MITH23245 <div className="research-anims" ref={animationContainer2} style={{ width: '64px', height: '64px' }}/>
              </div>
            </Item>
            <Item delay={1.2}>
              <p className="research-paragraph">This research study focusses upon the implementation of natural language processing within the project specifications section of Reach A.I. Software<span style={{fontSize: '0.7rem',verticalAlign: 'top',display: 'inline',marginLeft: '0.1rem',}}>®</span> procurement platform. Natural language processing is an advanced artificial intelligence technique that allows the comprehension of human desire via the identification of certain tags or markers in text or visual-based content. This MIT study explores the feasibility, including the benefits involved, with regards to implementing NLP within Reach A.I. Software<span style={{fontSize: '0.7rem',verticalAlign: 'top',display: 'inline',marginLeft: '0.1rem',}}>®</span>, to improve the efficiency and accuracy of matching the buyer’s request with the supplier’s quotation.</p>
            </Item>
        </div>
      </div>
    </div>
  );
};


export default Research;