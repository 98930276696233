import { Component } from "react";

import "../styles/NavbarTDStyles.scss";

class NavbarTD extends Component {


  render() {

    const scrollToSection = (sectionId) => {
      const section = document.getElementById(sectionId);
      const sectionTopOffset = section.offsetTop;
      const windowHeight = window.innerHeight;
      const scrollToPosition = sectionTopOffset - (windowHeight / 4);

      window.scrollTo({
        top: scrollToPosition,
        behavior: 'smooth',
      });
    };

    const handleClick = (event, sectionId) => {
      event.preventDefault();
      scrollToSection(sectionId);
    };

    // const onClick1 = () => {
    //     window.location.href = "#section-1";
    //   };

    //   const onClick2 = () => {
    //     window.location.href = "#section-2";
    //   };

    //   const onClick3 = () => {
    //     window.location.href = "#section-3";
    //   };

    //   const onClick4 = () => {
    //     window.location.href = "#section-4";
    //   };

    return (
      <nav className="NavbarTD">
        <ul className="tdul">
          <li key="AI" style={{"--i":"4","--clr":"#1877f2"}}><a className="aTD" href="#section-1" onClick={(e) => handleClick(e, 'section-1')}>Artificial Intelligence</a></li>
          <li key="IS" style={{"--i":"3","--clr":"#1877f2"}}><a className="aTD" href="#section-2" onClick={(e) => handleClick(e, 'section-2')}>Intelligence Services</a></li>
          <li key="PRC" style={{"--i":"2","--clr":"#1877f2"}}><a className="aTD" href="#section-3" onClick={(e) => handleClick(e, 'section-3')}>Procurement</a></li>
          <li key="LOG" style={{"--i":"1","--clr":"#1877f2"}}><a className="aTD" href="#section-4" onClick={(e) => handleClick(e, 'section-4')}>Logistics</a></li>
        </ul>
      </nav>
    );
  }
}

export default NavbarTD;
