import React, { Component } from "react";
import "../styles/FooterStyles.scss";
import { Container, Row, Col } from "react-bootstrap";
import Android from "../assets/Android.png";
import Ios from "../assets/IOS.png";
import EUR from "../assets/EURLOGO.png";
import SNN from "../assets/SNNFC.png";
import LinkedIn from "../assets/Social/linkedinw.png";

class Footer extends Component {
  state = {};

  render() {
    // const year = new Date().getFullYear();
    return (
      <>
        <footer>
          <Container fluid>
            <Row>
              <hr className="HrFade" />
            </Row>
            <Row>
              <Col xs={12} sm={4} className="column-start">
                <Row>
                  <p className="footerP">
                    Copyright © Reach A.I. Software B.V. 2022
                  </p>
                </Row>
                <Row>
                  <p className="footerP">Chamber of Commerce ID: 88191419</p>
                </Row>
                <Row className="Logos">
                  <Col className="LogosC">
                    <img alt="EU Logo" src={EUR} className="LogoImg" />
                    <img alt="SNN Logo" src={SNN} className="LogoImg" />
                  </Col>
                </Row>
              </Col>

              <Col xs={12} sm={4} className="column-end">
                <Row>
                  <Col>
                    <p className="AppTitle">Download Mobile Application</p>
                  </Col>
                </Row>
                <Row>
                  <Col
                    xs={6}
                    className="mx-auto text-center"
                    style={{ display: "flex", justifyContent: "end" }}
                  >
                    <a
                      className="AppLink"
                      href="https://play.google.com/store/apps/details?id=com.icfs.reach"
                    >
                      <Row>
                        <Col
                          className="mx-auto text-center"
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <img
                            alt="Android Logo"
                            src={Android}
                            className="appImg"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          className="mx-auto text-center"
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <p className="AppLinkTxt">Android</p>
                        </Col>
                      </Row>
                    </a>
                  </Col>
                  <Col
                    xs={6}
                    className="mx-auto text-center"
                    style={{ display: "flex", justifyContent: "start" }}
                  >
                    <a
                      className="AppLink"
                      href="https://apps.apple.com/nl/app/reach-software/id1573459006?l=en"
                    >
                      <Row>
                        <Col
                          className="mx-auto text-center"
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <img alt="IOS Logo" src={Ios} className="appImg" />
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          className="mx-auto text-center"
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <p className="AppLinkTxt">IOS</p>
                        </Col>
                      </Row>
                    </a>
                  </Col>
                </Row>
              </Col>


              <Col xs={12} sm={2} className="column-mid">
                      <Row>
                        <Col>
                          <p className="SocialTitle">Find us on</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          xs={1}
                          className="mx-auto text-center"
                          style={{ display: "flex", justifyContent: "start" }}
                        >
                          <a
                            className="SocialLink"
                            href="https://www.linkedin.com/company/reachsoftware-ai/"
                          >
                            <Row>
                              <Col
                                xs={1}
                                className="mx-auto text-center SocialBar"
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <img
                                  alt="Linkedin"
                                  src={LinkedIn}
                                  className="SocialImg"
                                />
                              </Col>
                            </Row>
                          </a>
                        </Col>
                      </Row>
                    </Col>


            </Row>
          </Container>
        </footer>
      </>
    );
  }
}

export default Footer;
