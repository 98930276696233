import { useCallback, useMemo } from "react";
import Particles from "react-particles";
import { loadFull } from "tsparticles";
import { loadPolygonMaskPlugin } from "tsparticles-plugin-polygon-mask";
// import type { Engine, ISourceOptions } from "tsparticles-engine";
// import  Brain from '../Brain.svg';
import "../styles/ParticleStyles.scss"

const ParticlesComponent2 = () => {
  let width = window.innerWidth;
  let posX = 0;
  let scale = 1;
  let posY = 0
  let nodeCount = 0;
  if (width > 1000) {
    nodeCount = 150;
    posX = 40;
    posY = 40;
    scale = 0.6;
  }
  else if (width <= 1000 && width>900) {
    nodeCount = 150;
    posX = 30;
    posY = 40;
    scale = 0.6;
  }
  else if(width <= 900 && width > 768){
    nodeCount = 150;
    posX = 28;
    posY = 40;
    scale = 0.6;
  }
  else if(width <= 768 && width > 576){
    //TODO: MOTO in this width is Small!
    nodeCount = 150;
    posX = 30;
    posY = 50;
    scale = 0.5;
  }
  else if(width <= 576 && width > 490){
    nodeCount = 150;
    posX = 50;
    posY = 40;
    scale = 0.5;
  }
  else if(width <= 490 && width > 395){
    nodeCount = 150;
    posX = 20;
    posY = 40;
    scale = 0.5;
  }
  else if(width <= 395){
    nodeCount = 100;
    posX = 15;
    posY = 40;
    scale = 0.5;
  }

    const options = useMemo(()=>{
        return {
                "autoPlay": true,
                "fullScreen": {
                  "enable": false
                },
                "detectRetina": false,
                "duration": 0,
                "fpsLimit": 120,
                "interactivity": {
                  "detectsOn": "canvas",
                  "events": {
                    "onClick": {
                      "enable": false,
                      "mode": "push"
                    },
                    "onDiv": {
                      "selectors": "#repulse-div",
                      "enable": false,
                      "mode": "repulse",
                      "type": "circle"
                    },
                    "onHover": {
                      "enable": true,
                      "mode": "bubble",
                      "parallax": {
                        "enable": false,
                        "force": 2,
                        "smooth": 10
                      }
                    },
                    "resize": {
                      "delay": 0.5,
                      "enable": true
                    }
                  },
                  "modes": {
                    "attract": {
                      "distance": 200,
                      "duration": 0.4,
                      "easing": "ease-out-quad",
                      "factor": 1,
                      "maxSpeed": 50,
                      "speed": 1
                    },
                    "bounce": {
                      "distance": 200
                    },
                    "bubble": {
                      "distance": 40,
                      "duration": 2,
                      "mix": false,
                      "opacity": 8,
                      "size": 6,
                      "divs": {
                        "distance": 200,
                        "duration": 0.4,
                        "mix": false,
                        "selectors": []
                      }
                    },
                    "connect": {
                      "distance": 40,
                      "links": {
                        "opacity": 0.5
                      },
                      "radius": 30
                    },
                    "grab": {
                      "distance": 400,
                      "links": {
                        "blink": false,
                        "consent": false,
                        "opacity": 1
                      }
                    },
                    "push": {
                      "default": true,
                      "groups": [],
                      "quantity": 4
                    },
                    "remove": {
                      "quantity": 2
                    },
                    "repulse": {
                      "distance": 100,
                      "duration": 0.4,
                      "factor": 100,
                      "speed": 1,
                      "maxSpeed": 50,
                      "easing": "ease-out-quad",
                      "divs": {
                        "distance": 200,
                        "duration": 0.4,
                        "factor": 100,
                        "speed": 1,
                        "maxSpeed": 50,
                        "easing": "ease-out-quad",
                        "selectors": []
                      }
                    },
                    "slow": {
                      "factor": 1,
                      "radius": 0
                    },
                    "trail": {
                      "delay": 1,
                      "pauseOnStop": false,
                      "quantity": 1
                    },
                    "light": {
                      "area": {
                        "gradient": {
                          "start": {
                            "value": "#A16CA4"
                          },
                          "stop": {
                            "value": "#4CB9DF"
                          }
                        },
                        "radius": 1000
                      },
                      "shadow": {
                        "color": {
                          "value": "#000000"
                        },
                        "length": 2000
                      }
                    }
                  }
                },

                "particles": {
                  "bounce": {
                    "horizontal": {
                      "random": {
                        "enable": false,
                        "minimumValue": 0.1
                      },
                      "value": 1
                    },
                    "vertical": {
                      "random": {
                        "enable": false,
                        "minimumValue": 0.1
                      },
                      "value": 1
                    }
                  },
                  "collisions": {
                    "absorb": {
                      "speed": 2
                    },
                    "bounce": {
                      "horizontal": {
                        "random": {
                          "enable": true,
                          "minimumValue": 0.1
                        },
                        "value": 1
                      },
                      "vertical": {
                        "random": {
                          "enable": false,
                          "minimumValue": 0.1
                        },
                        "value": 1
                      }
                    },
                    "enable": false,
                    "mode": "bounce",
                    "overlap": {
                      "enable": true,
                      "retries": 0
                    }
                  },
                  "color": {
                    "value": ["#A16CA4", "#816EAA", "#4E71B3", "#EA4335"],
                    // "animation": {
                    //   "h": {
                    //     "count": 0,
                    //     "enable": true,
                    //     "offset": 0,
                    //     "speed": 1,
                    //     "decay": 0,
                    //     "sync": true
                    //   },
                    //   "s": {
                    //     "count": 0,
                    //     "enable": true,
                    //     "offset": 0,
                    //     "speed": 1,
                    //     "decay": 0,
                    //     "sync": true
                    //   },
                    //   "l": {
                    //     "count": 0,
                    //     "enable": true,
                    //     "offset": 0,
                    //     "speed": 1,
                    //     "decay": 0,
                    //     "sync": true
                    //   }
                    // }
                  },
                  "move": {
                    "angle": {
                      "offset": 0,
                      "value": 90
                    },
                    "center": {
                      "x": 50,
                      "y": 50,
                      "mode": "percent",
                      "radius": 0
                    },
                    "decay": 0,
                    "distance": {},
                    "direction": "none",
                    "drift": 0,
                    "enable": true,
                    "path": {
                      "clamp": true,
                      "delay": {
                        "random": {
                          "enable": true,
                          "minimumValue": 0
                        },
                        "value": 0
                      },
                      "enable": false,
                      "options": {}
                    },
                    "outModes": {
                      "default": "bounce",
                      "bottom": "bounce",
                      "left": "bounce",
                      "right": "bounce",
                      "top": "bounce"
                    },
                    "random": false,
                    "size": false,
                    "speed": 2,
                    "spin": {
                      "acceleration": 0,
                      "enable": false
                    },
                    "straight": false,
                    "trail": {
                      "enable": false,
                      "length": 10,
                      "fill": {}
                    },
                    "vibrate": false,
                    "warp": true
                  },
                  "number": {
                    "density": {
                      "enable": false,
                      "width": 1920,
                      "height": 1080
                    },
                    "limit": 0,
                    "value": nodeCount
                  },
                  "opacity": {
                    "random": {
                      "enable": false,
                      "minimumValue": 0.1
                    },
                    "value": {
                      "min": 0.05,
                      "max": 0.4
                    },
                    "animation": {
                      "count": 0,
                      "enable": true,
                      "speed": 2,
                      "decay": 0,
                      "sync": false,
                      "destroy": "none",
                      "startValue": "random",
                      "minimumValue": 0.05
                    }
                  },
                  "reduceDuplicates": false,
                  "shadow": {
                    "blur": 0,
                    "color": {
                      "value": "#000"
                    },
                    "enable": false,
                    "offset": {
                      "x": 0,
                      "y": 0
                    }
                  },
                  "shape": {
                    "options": {},
                    "type": "circle"
                  },
                  "size": {
                    "random": {
                      "enable": true,
                      "minimumValue": 1
                    },
                    "value": 1,
                    "animation": {
                      "count": 0,
                      "enable": false,
                      "speed": 10,
                      "decay": 0,
                      "sync": false,
                      "destroy": "none",
                      "startValue": "random",
                      "minimumValue": 0.1
                    }
                  },
                  "stroke": {
                    "width": 0
                  },
                  "zIndex": {
                    "random": {
                      "enable": false,
                      "minimumValue": 0
                    },
                    "value": 0,
                    "opacityRate": 1,
                    "sizeRate": 1,
                    "velocityRate": 1
                  },
                  "life": {
                    "count": 0,
                    "delay": {
                      "random": {
                        "enable": false,
                        "minimumValue": 0
                      },
                      "value": 0,
                      "sync": false
                    },
                    "duration": {
                      "random": {
                        "enable": false,
                        "minimumValue": 0.0001
                      },
                      "value": 0,
                      "sync": false
                    }
                  },
                  "rotate": {
                    "random": {
                      "enable": true,
                      "minimumValue": 0
                    },
                    "value": 0,
                    "animation": {
                      "enable": false,
                      "speed": 0,
                      "decay": 0,
                      "sync": false
                    },
                    "direction": "clockwise",
                    "path": false
                  },
                  "destroy": {
                    "bounds": {},
                    "mode": "none",
                    "split": {
                      "count": 1,
                      "factor": {
                        "random": {
                          "enable": false,
                          "minimumValue": 0
                        },
                        "value": 3
                      },
                      "rate": {
                        "random": {
                          "enable": false,
                          "minimumValue": 0
                        },
                        "value": {
                          "min": 4,
                          "max": 9
                        }
                      },
                      "sizeOffset": true,
                      "particles": {}
                    }
                  },
                  "roll": {
                    "darken": {
                      "enable": false,
                      "value": 0
                    },
                    "enable": false,
                    "enlighten": {
                      "enable": false,
                      "value": 0
                    },
                    "mode": "vertical",
                    "speed": 25
                  },
                  "tilt": {
                    "random": {
                      "enable": false,
                      "minimumValue": 0
                    },
                    "value": 0,
                    "animation": {
                      "enable": false,
                      "speed": 0,
                      "decay": 0,
                      "sync": false
                    },
                    "direction": "clockwise",
                    "enable": false
                  },
                  "twinkle": {
                    "lines": {
                      "enable": false,
                      "frequency": 0.05,
                      "opacity": 1
                    },
                    "particles": {
                      "enable": false,
                      "frequency": 0.05,
                      "opacity": 1
                    }
                  },
                  "wobble": {
                    "distance": 5,
                    "enable": false,
                    "speed": {
                      "angle": 50,
                      "move": 10
                    }
                  },
                  "orbit": {
                    "animation": {
                      "count": 0,
                      "enable": false,
                      "speed": 1,
                      "decay": 0,
                      "sync": false
                    },
                    "enable": false,
                    "opacity": 1,
                    "rotation": {
                      "random": {
                        "enable": false,
                        "minimumValue": 0
                      },
                      "value": 45
                    },
                    "width": 1
                  },
                  "links": {
                    "blink": false,
                    "color": {
                      "value": ["#A16CA4", "#816EAA", "#4E71B3", "#EA4335"]
                    },
                    "color": "random",
                    "consent": false,
                    "distance": 40,
                    "enable": true,
                    "frequency": 1,
                    "opacity": 1,
                    "shadow": {
                      "blur": 1,
                      "color": "random",
                      "enable": false
                    },
                    "triangles": {
                      "enable": true,
                      "frequency": 0.01
                    },
                    "width": 1,
                    "warp": false
                  },
                  "repulse": {
                    "random": {
                      "enable": false,
                      "minimumValue": 0
                    },
                    "value": 0,
                    "enabled": false,
                    "distance": 1,
                    "duration": 1,
                    "factor": 1,
                    "speed": 1
                  }
                },
                "pauseOnBlur": true,
                "pauseOnOutsideViewport": true,
                "responsive": [],
                "smooth": true,
                "zLayers": 100,
                "polygon": {
                  "draw": {
                    "enable": true,
                    "stroke": {
                      "color": {
                        "value": "#A16CA4"

                      },
                      "width": 1,
                      "opacity": 0.5
                    }
                  },
                  "enable": true,
                  "inline": {
                    "arrangement": "equidistant"
                  },
                  "move": {
                    "radius": 10,
                    "type": "path"
                  },
                  "scale": scale,
                  "type": "inline",
                  "url": "./Brain.svg",
                  position: {
                    x: posX,
                    y: posY,
                },
                }
        };
    }, []);

  const particlesInit = useCallback(async engine => {
    await loadFull(engine);
    await loadPolygonMaskPlugin(engine);
}, []);

const particlesLoaded = useCallback(async container => {
    // await console.log(container);
}, []);
    return <Particles
    id="tsparticles-custom"
    init={particlesInit}
    loaded={particlesLoaded}
    options={options}
/>;
};

export default ParticlesComponent2;