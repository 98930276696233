import React from "react";
import { useEffect, useRef } from "react";
import "../styles/DownloadStyles.scss";
import { Row, Col, Container, Button } from "react-bootstrap";
import ThemeProvider from "react-bootstrap/ThemeProvider";
import Highway from "highway";
import CircularProgress from "../components/CircularProgress";
import {
  MouseParallaxChild,
  MouseParallaxContainer,
} from "react-parallax-mouse";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import ForkTree from "../components/ForkTree";
import lottie from 'lottie-web';
import animationData from '../assets/IOS2.json';

export default function Download() {

  const animationContainer = useRef(null);
  let animationInstance = useRef(null);

  const { ref, inView } = useInView({
    threshold: 0.15,
  });

  const animation = useAnimation();

  useEffect(() => {
    animationInstance.current = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: animationData,
    });

    return () => {
      if (animationInstance.current) {
        animationInstance.current.destroy();
        animationInstance.current = null;
      }
    };
  }, []);

  useEffect(() => {
    if (inView) {
      animation.start({
        y: 0,
        opacity: 1,
        transition: {
          type: "spring",
          duration: 2,
          bounce: 0.3,
          delay: 0,
        },
      });
    }
    if (!inView) {
      animation.start({
        y: 200,
        opacity: 0,
      });
    }

    // console.log("use effect hook, inView =", inView);
  }, [inView]);

  return (
    <>
      <ThemeProvider
        breakpoints={["xxxl", "xxl", "xl", "lg", "md", "sm", "xs", "xxs"]}
        minBreakpoint="xxs"
      >
        <Container fluid style={{ margin: 0, padding: 0 }}>
          <Row>
            <section className="DownloadSection1">
              <motion.div
                className="swipe-element"
                initial={{ opacity: 0, x: -100 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{
                  type: "spring",
                  duration: 2,
                  bounce: 0.3,
                  delay: 0.5,
                }} // Delay the swipe animation for 2 seconds after the SVG animation completes
              >
                <Row>
                  <Col className="col-12 d-flex justify-content-center">
                    <div className="dltitle1">
                      <h4>
                      In October 2023, Microsoft announced that the Xamarin product lifecycle was coming to an end. There will no longer be support offered to Applications built using Xamarin & Xamarin.forms.</h4>
                      <a href="https://dotnet.microsoft.com/en-us/platform/support/policy/xamarin"><h5 className="dltitle2" target="_blank" rel="noreferrer">[Read More]</h5></a>
                      <h4>
                      As a result, we upgraded REACH A.I. Software<span style={{fontSize: '0.7rem',verticalAlign: 'top',display: 'inline',marginLeft: '0.1rem',}}>®</span> to .NET MAUI (Multi-platform App UI). This upgrade enabled the Software to function cross-platform.
                      </h4>
                    </div>
                  </Col>
                </Row>
              </motion.div>
              <Row>
                <Col></Col>
                <Col className="col-1 d-flex justify-content-center" target="_blank" rel="noreferrer">
                  <div className="dl1">
                    <CircularProgress />
                  </div>
                </Col>
                <Col></Col>
              </Row>
              {/* </motion.div> */}
              <Row>
                <Col>
                  <ForkTree />
                </Col>
              </Row>
              <Row>
                <Col>
                <a href="https://play.google.com/store/apps/details?id=com.icfs.reach" target="_blank" rel="noreferrer">  
                  <Row>
                    <Col>
                      <img
                        src="Images/Android.gif"
                        className="PlatformLogos Android"
                      />
                    </Col>
                  </Row>
                  <Row className="DownloadTitle">
                    <Col>Android</Col>
                  </Row>
                  </a>
                </Col>
                <Col>
                <a href="https://apps.apple.com/nl/app/reach-software/id1573459006?l=en" target="_blank" rel="noreferrer"> 
                  <Row>
                    <Col className="PlatformLogos IOS" ref={animationContainer}>

                    </Col>
                  </Row>

                  <Row className="DownloadTitle">
                    <Col>IOS</Col>
                  </Row>
                  </a>
                </Col>
                <Col>
                <a href="https://apps.apple.com/nl/app/reach-software/id1573459006?l=en" target="_blank" rel="noreferrer"> 
                  <Row>
                    {" "}
                    <Col>
                      <img
                        src="Images/Mac.gif"
                        className="PlatformLogos Mac"
                      />
                    </Col>
                  </Row>

                  <Row className="DownloadTitle">
                    <Col>Mac</Col>
                  </Row>
                  </a>
                </Col>
                <Col>
                <a href="https://apps.microsoft.com/detail/9nc6hx6z2xv1?hl=en-US&gl=US" target="_blank" rel="noreferrer">
                  <Row>
                    {" "}
                    <Col>
                      <img
                        src="Images/Windows.gif"
                        className="PlatformLogos Windows"
                      />
                    </Col>
                  </Row>

                  <Row className="DownloadTitle">
                    <Col>Windows</Col>
                  </Row>
                  </a>
                </Col>
              </Row>
            </section>
          </Row>
        </Container>
      </ThemeProvider>
    </>
  );
}
