import "../styles/Algorithms.scoped.scss";
import React from "react";
import { Row, Col, Container, Button } from "react-bootstrap";
import ThemeProvider from "react-bootstrap/ThemeProvider";
// import AnimationFM from "../components/AnimationFM";
// import AnimationSM from "../components/AnimationSM";
// import ModelA from "../assets/ModelA.png";
// import ModelB from "../assets/ModelB.png";
// import { Model1 } from "../components/Model1";
// import { Model2 } from "../components/Model2";
// import { Canvas } from "react-three-fiber";
// import { OrbitControls } from "@react-three/drei";
// import { PerspectiveCamera } from "three";
import VideoPlayer from '../components/VideoPlayer';
import ReqDemo from "../components/ReqDemo";
import EUR from "../assets/EURLOGO.png";

function Algorithms() {
  // const cameraPosition = { x: 35, y: 20, z: 15 }; // Adjust the z value as needed
  // const camera = new PerspectiveCamera(
  //   60,
  //   window.innerWidth / window.innerHeight,
  //   0.1,
  //   500
  // );
  // camera.position.set(cameraPosition.x, cameraPosition.y, cameraPosition.z);

  return (
    <>
      <ThemeProvider
        breakpoints={["xxxl", "xxl", "xl", "lg", "md", "sm", "xs", "xxs"]}
        minBreakpoint="xxs"
      >
        <Container className="min-vh-100">
          <Row>
            <Col>
              <h1 className="algTitle">Algorithms</h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <Row className="algText">
                <h4>
                  Our unique Machine-Learning algorithm for{" "}
                  <span className="un">REACH A.I. Software<span style={{fontSize: '0.7rem',verticalAlign: 'top',display: 'inline',marginLeft: '0.1rem',}}>®</span></span> provides the
                  following functions:
                </h4>
              </Row>
              <Row>
                <ul>
                  <li className="algSubMoto">Dynamic Pricing</li>
                  <li className="algSubMoto">Budget Optimization</li>
                  <li className="algSubMotoSimple"><Row><Col className="algSubMotoDiv"><img alt="EU Logo" src={EUR} className="algLogoImg" />Co-funded by ERDF - 2021 VIA Grant ID VIP21S069</Col></Row></li>
                </ul>
              </Row>
              <Row className="algText">
                <h4>
                  <span className="un">REACH A.I. Software<span style={{fontSize: '0.7rem',verticalAlign: 'top',display: 'inline',marginLeft: '0.1rem',}}>®</span>'s</span> algorithm can be
                  applied in the following industries:
                </h4>
              </Row>
              <Row className="algText algBullets">
                <ul>
                  <li>Logistics</li>
                  <li>Procurement</li>
                  <li>Freight-Forwarding</li>
                  <li>Development Out-Sourcing</li>
                  <li>Supply Chain Management</li>
                </ul>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <ReqDemo />
            </Col>
          </Row>
          <Row>
            <Col>
              <h1 className="algTitle2">License Types</h1>
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <Row
                style={{
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                  alignItems: "center",
                  maxWidth: "100%",
                }}
              >
                <Col className="algContainer First algCardMB">
                  <Row className="algCanvasTitle align-items-center">
                    <Col className="algTitleFirst">License Type A</Col>
                  </Row>
                  <Row>
                    <Col className="algBodyFirst">
                      {/* <img src={ModelA} className="imgA" alt="ModelA" /> */}
                      {/* <Canvas
                        camera={camera}
                        style={{ height: "100%", width: "100%" }}
                      >
                        <OrbitControls enabled={false} />
                        <ambientLight intensity={0.8} />
                        <directionalLight intensity={0.5} />
                        <Suspense fallback={null}>
                          <Model1 />
                        </Suspense>
                      </Canvas> */}

                      <VideoPlayer videoUrl={process.env.PUBLIC_URL + "/Model2.mp4"} />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col lg={6}>
              <Row
                style={{
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                  alignItems: "center",
                  maxWidth: "100%",
                }}
              >
                <Col className="algContainer Second algCardMB">
                  <Row className="algCanvasTitle align-items-center">
                    <Col className="algTitleSecond ">License Type B</Col>
                  </Row>
                  <Row>
                    <Col className="algBodySecond">
                    <VideoPlayer videoUrl={process.env.PUBLIC_URL + "/Model1.mp4"} />


                      {/* <img src={ModelB} className="imgA" alt="ModelB" /> */}
                      {/* <Canvas
                        camera={camera}
                        style={{ height: "100%", width: "100%" }}
                      >
                        <OrbitControls enabled={false}  />
                        <ambientLight intensity={0.8} />
                        <directionalLight intensity={0.5} />
                        <Suspense fallback={null}>
                          <Model2 />
                        </Suspense>
                      </Canvas> */}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </ThemeProvider>
    </>
  );
}
export default Algorithms;
