import React from "react";
import { Container, Row, Col, ListGroup, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faInstagram,
  faLinkedin,
  faAndroid,
  faApple,
  faMicrosoft
} from "@fortawesome/free-brands-svg-icons";
import styled from "styled-components";
import EUR from "../assets/EURLOGO.png";
import SNN from "../assets/SNNFC.png";
import "../styles/Footer2Styles.scss";

import colorMicrosoft from "../assets/color-microsoft.svg";
import colorAndroid from "../assets/color-android.svg";
import colorMac from "../assets/mac-studio.png";

const TreeView = styled.ul`
  list-style: none;
  padding-left: 0;

  .tree-item {
    position: relative;
    list-style-type: none;
    cursor: pointer;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: -20px;
      bottom: 0;
      width: 1px;
      background: #d1d1d1;
    }

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: -20px;
      width: 20px;
      height: 1px;
      background: #d1d1d1;
    }

    &:first-child:after {
      top: 50%;
      height: 50%;
    }

    &:last-child:after {
      height: 50%;
    }
  }

  .tree-item-label {
    margin-left: 5px;
  }
`;

const Footer2 = () => {
  return (
    <footer className="mt-auto py-4">
      <Container>
        <Row>
          <hr className="HrFade" />
        </Row>
        <Row>
          <Col xs={12} md={4}>
            <p className="Footertxts">
              Copyright © Reach A.I. Software B.V. 2022
              <br />
              Chamber of Commerce ID: 88191419
              <br />
              <div className="FooterLogos">
              <img alt="EU Logo" src={EUR} className="LogoImg" />
              <a href="https://www.snn.nl/" target="_blank" rel="noreferrer" className="FooterLink"><img alt="SNN Logo" src={SNN} className="LogoImg" /></a>
              </div>
            </p>
          </Col>

          <Col xs={12} md={4}></Col>

          <Col xs={12} md={4}>
            <div className="mt-3">
              <h5 className="SocTit">Downloads</h5>
              <Button
                className="AppBtns"
                variant="outline-secondary"
                href="https://play.google.com/store/apps/details?id=com.icfs.reach"
                target="_blank"
              >
                {/*<FontAwesomeIcon
                  icon={faAndroid}
                  size="lg"
                  className="AppIcos"
  />*/}
  <img src={colorAndroid} alt="Android Logo" className="AppIcosCustom"/>
                Android
              </Button>
              <Button
                className="AppBtns"
                variant="outline-secondary"
                href="https://apps.microsoft.com/detail/9nc6hx6z2xv1?hl=en-US&gl=US"
                target="_blank"
              >
                {/*<FontAwesomeIcon icon={faMicrosoft} size="lg" className="AppIcos" />*/}
                <img src={colorMicrosoft} alt="Microsoft Logo" className="AppIcosCustom"/>
                Windows
              </Button>
            </div>
            <div className="mt-3">
            <Button
                className="AppBtnsDown"
                variant="outline-secondary"
                href="https://apps.apple.com/nl/app/reach-software/id1573459006?l=en"
                target="_blank"
              >
                <FontAwesomeIcon icon={faApple} size="lg" className="AppIcos FotterApple" />
                iOS
              </Button>
              <Button
                className="AppBtnsDown"
                variant="outline-secondary"
                href="https://apps.apple.com/nl/app/reach-software/id1573459006?l=en"
                target="_blank"
              >
                {/*<FontAwesomeIcon icon={faApple} size="lg" className="AppIcos FotterApple" />*/}
                <img src={colorMac} alt="Microsoft Logo" className="AppIcosCustom"/>
                MacOS
              </Button>
            </div>
            <p className="SocTit">Follow Us</p>
            {/* <a href="#" className="mr-2">
              <FontAwesomeIcon
                icon={faFacebook}
                size="lg"
                className="SocialIco"
              />
            </a>
            <a href="#" className="mr-2">
              <FontAwesomeIcon
                icon={faTwitter}
                size="lg"
                className="SocialIco"
              />
            </a>
            <a href="#">
              <FontAwesomeIcon
                icon={faInstagram}
                size="lg"
                className="SocialIco"
              />
            </a> */}
            <a href="https://www.linkedin.com/company/reachsoftware-ai/" target="_blank" rel="noreferrer">
              <FontAwesomeIcon
                icon={faLinkedin}
                size="lg"
                className="SocialIco"
              />
            </a>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer2;
