import React, { useEffect, useRef } from "react";

const VideoPlayer = ({ videoUrl }) => {
  const videoRef = useRef(null);
  let loopIntervalId;

  const startLoop = () => {
    const videoElement = videoRef.current;
    const { duration } = videoElement;

    loopIntervalId = setInterval(() => {
      if (videoElement) {
        videoElement.currentTime = 0;
        videoElement.play();
      }
    }, 10000); // Use the video duration for the loop delay
  };

  const stopLoop = () => {
    clearInterval(loopIntervalId);
  };

  useEffect(() => {
    const videoElement = videoRef.current;
    videoRef.current.defaultMuted = true;
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5, // Percentage of the video element that needs to be visible to trigger autoplay
    };

    const callback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          videoElement.play();
          startLoop();
        } else {
          videoElement.pause();
          stopLoop();
        }
      });
    };

    const observer = new IntersectionObserver(callback, options);
    observer.observe(videoElement);
    // startLoop();

    return () => {
      observer.unobserve(videoElement);
      stopLoop();
    };
  }, []);

  return (
    <div className="video-player">
      <video ref={videoRef} autoPlay muted playsInline webkit-playsinline={true}>
        <source src={videoUrl} type="video/mp4" />
      </video>
    </div>
  );
};

export default VideoPlayer;
