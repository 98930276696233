export const SectorsData = [
  // {
  //   title: "Manufacturing & Production",
  //   info: (
  //     <ul className="Sectorul">
  //       <li>Coffee</li>
  //       <li>Tea</li>
  //       <li>Milk</li>
  //     </ul>
  //   ),
  //   banner: "Images/Sectors/Factory.gif",
  //   cName: "sec-card",
  //   icon: "fa-solid fa-house-user",
  // },
  {
    title: "A.I. Development",
    info: (
      <ul className="Sectorul">
        <li key="Agriculture">Agriculture</li>
        <li key="Automotive">Automotive</li>
        <li key="">Construction</li>
        <li key="Construction">Education</li>
        <li key="Energy">Energy</li>
        <li key="Entertainment">Entertainment & Media</li>
        <li key="Food">Food & Beverage</li>
        <li key="Finance">Finance</li>
        <li key="Government">Government & Public Sector</li>
        <li key="Logistics">Logistics</li>
        <li key="Manufacturing">Manufacturing & Production</li>
        <li key="Medical">Medical</li>
        <li key="Retail">Retail</li>
        <li key="Transportation">Transportation</li>
        <li key="Urban">Urban Engineering</li>
      </ul>
    ),
    banner: "Images/Sectors/AI.gif",
    cName: "sec-card",
    icon: "fa-solid fa-house-user",
    section: "section-1",
  },
  {
    title: "Intelligence Services",
    info: (
      <ul className="Sectorul IService">
        <li key="CI">Corporate Intelligence</li>
        <li key="DI">Due Dilligence Services</li>
        <li key="FI">Financial Intelligence</li>
        <li key="OI">International Relations</li>
        <li key="OI">Cybersecurity Intelligence</li>
      </ul>
    ),
    banner: "Images/Sectors/face.gif",
    cName: "sec-card",
    icon: "fa-solid fa-house-user",
    section: "section-2",
  },
  {
    title: "Procurement",
    info: (
      <ul className="Sectorul">
        <li key="cs1">Coming Soon!</li>
      </ul>
    ),
    banner: "Images/Sectors/procurement.gif",
    cName: "sec-card",
    icon: "fa-solid fa-house-user",
    section: "section-3",
  },
  {
    title: "Logistics",
    info: (
      <ul className="Sectorul">
        <li key="cs2">Coming Soon!</li>
      </ul>
    ),
    banner: "Images/Sectors/LogisticsT.gif",
    cName: "sec-card",
    icon: "fa-solid fa-house-user",
    section: "section-4",
  },
];
