import TimelineItem from "./TimelineItem";
import {NewsData} from "./NewsData";

import "../styles/NewsTimeline.scss";

const Timeline = () =>
  NewsData.length > 0 && (
    <div className="timeline-container">
      {NewsData.map((data, idx) => (
        <TimelineItem data={data} idx={idx} key={idx} />
      ))}
    </div>
  );
export default Timeline;
