import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Cards from "../components/TeamCards";

function Team() {
  return (
    <Container fluid>
      <Row>
        <Cards />
      </Row>
    </Container>
  );
}
export default Team;
