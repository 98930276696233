import React from "react";
import { useEffect, useRef } from "react";
import "../styles/AboutStyles.scss";
import { Row, Col, Container, Button } from "react-bootstrap";
import ThemeProvider from "react-bootstrap/ThemeProvider";
import Highway from "highway";
import World from "../components/World";
import {
  MouseParallaxChild,
  MouseParallaxContainer,
} from "react-parallax-mouse";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import Tree from "../components/Tree";

export default function About() {
  const { ref, inView } = useInView({
    threshold: 0.15,
  });

  const animation = useAnimation();

  useEffect(() => {
    if (inView) {
      animation.start({
        y: 0,
        opacity: 1,
        transition: {
          type: "spring",
          duration: 2,
          bounce: 0.3,
          delay: 0,
        },
      });
    }
    if (!inView) {
      animation.start({
        y: 200,
        opacity: 0,
      });
    }

    // console.log("use effect hook, inView =", inView);
  }, [inView]);

  return (
    <>
      <ThemeProvider
        breakpoints={["xxxl", "xxl", "xl", "lg", "md", "sm", "xs", "xxs"]}
        minBreakpoint="xxs"
      >
        <Container fluid style={{ margin: 0, padding: 0 }}>
          <section className="AboutSection1">
            <Row>
              <World />
            </Row>
            {/* <Row>
              <Col>
                <motion.div
                  className="swipe-element"
                  initial={{ opacity: 0, y: -100 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{
                    type: "spring",
                    duration: 2,
                    bounce: 0.3,
                    delay: 2,
                  }} // Delay the swipe animation for 2 seconds after the SVG animation completes
                >
                  <Row>
                    <h1 className="AboutTitle">About Our Platform</h1>
                  </Row>
                </motion.div>
              </Col>
            </Row> */}
            <motion.div
              className="swipe-element"
              initial={{ opacity: 0, x: -100 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{
                type: "spring",
                duration: 2,
                bounce: 0.3,
                delay: 2,
              }} // Delay the swipe animation for 2 seconds after the SVG animation completes
            >
              <Row>
                <Col>
                  <Row>
                    {/* Your swipe element content */}

                    <h2 className="abLText">
                      Reach A.I. Software<span style={{fontSize: '0.7rem',verticalAlign: 'top',display: 'inline',marginLeft: '0.1rem',}}>®</span> is an A.I. powered cross-platform software that facilitates service providers to procure projects from clients in specific micro-niches. The software is accessible via{" "}
                      <span className="un">
                        <a
                          className="AIos"
                          href="https://apps.apple.com/nl/app/reach-software/id1573459006?l=en"
                        >
                          iOS
                        </a>
                      </span>
                      ,{" "}
                      <span className="un">
                        <a
                          className="AIos"
                          href="https://apps.apple.com/nl/app/reach-software/id1573459006?l=en"
                        >
                          MacOS
                        </a>
                      </span>
                      ,{" "}
                      <span className="un">
                        <a
                          className="AIos"
                          href="https://apps.microsoft.com/detail/9nc6hx6z2xv1?hl=en-US&gl=US"
                        >
                          Windows
                        </a>
                      </span>{" "}
                      &{" "}
                      <span className="un">
                        <a
                          className="AAndroid"
                          href="https://play.google.com/store/apps/details?id=com.icfs.reach"
                        >
                          Android
                        </a>
                      </span>{" "}
                      Applications.
                    </h2>
                  </Row>
                </Col>
              </Row>
            </motion.div>
            {/* <motion.div
              className="swipe-element"
              initial={{ opacity: 0, y: 100 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{
                type: "spring",
                duration: 2,
                bounce: 0.3,
                delay: 2,
              }} // Delay the swipe animation for 2 seconds after the SVG animation completes
            ></motion.div> */}
          </section>
          <Row ref={ref}>
            <section className="AboutSection2">
              <motion.div animate={animation}>
                <Row className="justify-content-center align-items-center">
                  <Col className="d-flex justify-content-center align-items-center">
                    <div className="vl3"></div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h1 className="FeaturesTitle">
                      <span className="un">Features:</span>
                    </h1>
                  </Col>
                </Row>
                <Row className="abText abBullets">
                  <ul>
                    <li>
                      Project Specifications & extensive service break-down
                    </li>
                    <li>Budget Advice & Dynamic Pricing backed by A.I.</li>
                    <li>Service Provider Invoice Generator</li>
                    <li>Built-in chat</li>
                    <li>
                      Payment Service Provider Integration&nbsp;
                      <span className="un">[coming&nbsp;soon]</span>
                    </li>
                    <li>
                      KYC & KYB Software Integration&nbsp;
                      <span className="un">[coming&nbsp;soon]</span>
                    </li>
                  </ul>
                </Row>
              </motion.div>
            </section>
            {/*
            <svg viewBox="0 0 500 50" preserveAspectRatio="xMinYMin meet">
              <path
                d="M500,0v46c-95,27-285-111-500,2l0-48L500,0z"
                style={{ stroke: "none", fill: "rgb(26, 158, 235)" }}
              ></path>
            </svg> */}
          </Row>
          {/* <Row>
            <section className="AboutSection3">
              <Row>

              </Row>
            </section>
          </Row> */}
          <Row>
            <section className="AboutSection4">
              {/* <motion.div animate={animation2}> */}
              <Row className="justify-content-center align-items-center">
                <Col className="d-flex justify-content-center align-items-center">
                  <div className="vl3"></div>
                </Col>
              </Row>

              <Row>
                <Col>
                  <h1 className="AboutTitle">Account Types:</h1>
                </Col>
              </Row>
              {/* </motion.div> */}
              <Row>
                <Col>
                  <Tree />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Row className="AboutTitle">Service Providers</Row>
                  <Row className="abText abBulletsC">
                    <ul>
                      <li>Established Companies</li>
                      <li>Professionals</li>
                    </ul>
                  </Row>
                </Col>
                <Col>
                  <Row className="AboutTitle">Clients</Row>
                  <Row className="abText abBulletsC">
                    <ul>
                      <li>Government Entities</li>
                      <li>Start-ups & SME's</li>
                      <li>MNC's</li>
                      <li>Educational Institutions</li>
                    </ul>
                  </Row>
                </Col>
              </Row>
            </section>
          </Row>
        </Container>
      </ThemeProvider>
    </>
  );
}
