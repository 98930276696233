import React from "react";
import { useEffect, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const Tree = () => {

  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);



  const { ref, inView } = useInView({
    threshold: 0.15,
  });
  const animation = useAnimation();
  const animation2 = useAnimation();
  const animation3 = useAnimation();
  const animation4 = useAnimation();

  useEffect(() => {
    if (inView) {

      animation.start({
        duration: 20,
        height: "100px",
      });

      animation2.start({
        duration: 20,
        delay: 2,
        width: screenSize.width/4,
      });

      animation3.start({
        duration: 20,
        delay: 2,
        width: screenSize.width/4,
      });

      animation4.start({
        duration: 20,
        delay: 4,
        height: "100px",
      });
    }
    if (!inView) {
      animation.start({
        height: "0px",
      });

      animation2.start({
        width: "0px",
      });

      animation3.start({
        width: "0px",
      });

      animation4.start({

        height: "0px",
      });
    }

    // console.log("use effect hook, inView =", inView);
  }, [inView]);

  return (
    <div ref={ref} style={{ position: "relative", height: "200px" }} className="TreeAnimation">
      <motion.div
        style={{
          width: "2px",
          height: "100px",
          background: "white",
          position: "absolute",
          left: "50%",
          top: "0",
        }}
        initial={{
          height: "0px",
        }}
        animate={animation}
      >
        <motion.div
          style={{
            width: "200px",
            height: "2px",
            background: "white",
            position: "absolute",
            top: "100px",
            left: -screenSize.width/4,
          }}
          initial={{
            width: "0px",
          }}
          animate={animation2}
        >
          <motion.div
            style={{
              width: "2px",
              height: "100px",
              background: "white",
              position: "absolute",
              top: "0",
            }}
            initial={{
              height: "0px",
            }}
            animate={animation4}
          />
        </motion.div>
        <motion.div
          style={{
            width: screenSize.width/4,
            height: "2px",
            background: "white",
            position: "absolute",
            top: "100px",
          }}
          initial={{
            width: "0px",
          }}
          animate={animation3}
        >
          <motion.div
            style={{
              width: "2px",
              height: "100px",
              background: "white",
              position: "absolute",
              left: screenSize.width/4,
              top: "0",
            }}
            initial={{
              height: "0px",
            }}
            animate={animation4}
          />
        </motion.div>
      </motion.div>
    </div>
  );
};

export default Tree;
