import "./App.scss";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Home from "./pages/Home";
import Sectors from "./pages/Sectors";
import Team from "./pages/Team";
import Layout from "./components/Layout";
import Enquire from "./pages/Enquire";
import Algorithms from "./pages/Algorithms";
import Research from "./pages/Research";
import About from "./pages/About";
import Download from "./pages/Download";
import News from "./pages/News";
import GA4React, { useGA4React } from "ga-4-react";

const ga4react = new GA4React("G-PTXCRWDF2M");

export default function App() {
  const ga = useGA4React();
  console.log(ga);
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route element={<Layout />}>
            <Route path="/" element={<Home />} />
            <Route path="Sectors" element={<Sectors />} />
            <Route path="Team" element={<Team />} />
            <Route path="Algorithms" element={<Algorithms />} />
            <Route path="Research" element={<Research />} />
            <Route path="Enquire" element={<Enquire />} />
            <Route path="Platform" element={<About />} />
            <Route path="Download" element={<Download />} />
            <Route path="Achievements" element={<News />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}
