import React from "react";


import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/CircularProgress.scss";

const CircularProgress = () => {
  return (
    
		<div class="progressbar">
    <svg class="progressbar__svg">
      <circle cx="80" cy="80" r="70" class="progressbar__svg-circle circle-port shadow-port"> </circle>
    </svg>
    <span class="progressbar__text shadow-port">100% Ported</span>
  </div>
  );
};

export default CircularProgress;
