import { Component } from "react";
import "../styles/MotoStyles.scss";
import { Container, Row, Col } from "react-bootstrap";

class MainMoto extends Component {
  render() {
    return (
      <div className="MainMotoBody">
        <Container>
          <Row className="justify-content-center">
            <Col className="d-flex justify-content-center">
              <a href="#" className="MainMoto dynamicFont">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                REACH A.I. SOFTWARE<div role="text" className="MainMotoR">®</div>
              </a>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col className="d-flex justify-content-center">
              <h3 className="SubMoto dynamicFont">
                Your industry, Our solution.
              </h3>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default MainMoto;
