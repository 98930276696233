import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import GA4React, { useGA4React } from "ga-4-react";

const ga4react = new GA4React("G-PTXCRWDF2M");

const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

(async () => {
  try {
    await ga4react.initialize();
  } catch (e) {
    console.error("Addblock is activated");
  }

  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
})();

reportWebVitals();
