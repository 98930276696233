import React, { useRef } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import emailjs from "@emailjs/browser";

const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#3f51b5",
    },
    secondary: {
      main: "#2196f3",
    },
  },
});

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


function isEmail(val) {
  let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if(!regEmail.test(val)){
    return 0;
  }
  return 1;
}

export default function ReqDemo() {
  const formRef = useRef(null);

  const [state, setState] = React.useState({
    open: false,
    type: "success",
    message: "Message Sent.",
  });
  const { open, type, message } = state;

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setState(false, "success", "Demo Request Sent");
  };

  const sendTest = (e) => {
    e.preventDefault();
    const formData = new FormData(formRef.current);
    if(isEmail(formData.get("email"))===0){
      console.log(" Invalid Email");
      setState({
        open: true,
        type: "error",
        message: "Please enter a valid Email!",
      });
      return;
    }
    emailjs
      .send(
        "service_ixqr23s",
        "template_t1irr4q",
        {
          email: formData.get("email"),
        },
        "7pyXVbFMe_xYtDucY"
      )
      .then(
        (result) => {
          setState({
            open: true,
            type: "success",
            message: "Demo Request Sent",
          });
          console.log(result.text);
        },
        (error) => {
          setState({
            open: true,
            type: "error",
            message: "There was an Error sending request!",
          });
          console.log(error.text);
        }
      );
  };

  return (
    <ThemeProvider theme={theme}>
      <Container
        component="main"
        maxWidth="xs"
        sx={{ marginTop: 5, marginBottom: 1 }}
      >
        <CssBaseline />
        <Box
          sx={{
            marginTop: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            component="form"
            ref={formRef}
            noValidate
            onSubmit={sendTest}
            sx={{ mt: 3 }}
          >
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="email"
                label="Company Email"
                name="email"
                autoComplete="email"
              />
            </Grid>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 0 }}
            >
              Request Live Demo
            </Button>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
              <Alert
                onClose={handleClose}
                severity={type}
                sx={{ width: "100%" }}
              >
                {message}
              </Alert>
            </Snackbar>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
